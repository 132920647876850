import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {isAbsoluteUrl} from '@common/core/utils/is-absolute-url';

@Component({
    selector: 'social-button-list',
    templateUrl: './social-button-list.component.html',
    styleUrls: ['./social-button-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialButtonListComponent {
    services: {type: string; url: string}[];

    @Input() set config(config: {[key: string]: string}) {
        this.services = Object.entries(config)
            .filter(([_, url]) => !!url)
            .map(([type, url]) => {
                return {
                    type,
                    url: this.buildUrl(type, url),
                };
            });
    }

    private buildUrl(service: string, value: string): string {
        if (!value || isAbsoluteUrl(value)) {
            return value;
        }
        value = value.replace('@', '');
        if (service === 'twitter') {
            return `https://twitter.com/${value}`;
        } else if (service === 'instagram') {
            return `https://instagram.com/${value}`;
        } else if (service === 'tiktok') {
            return `https://tiktok.com/${value}`;
        } else if (service === 'mail') {
            return `mailto:${value}`;
        } else if (service === 'whatsapp') {
            return `https://api.whatsapp.com/send?phone=${value}`;
        }
    }
}
