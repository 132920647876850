import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import {AuthGuard} from '@common/guards/auth-guard.service';
import {ContactComponent} from '@common/contact/contact.component';
import {LinkPreviewHostComponent} from './shared/link-preview/link-preview-host/link-preview-host.component';
import {NOT_FOUND_ROUTES} from '@common/pages/not-found-routes';
import {GuestGuard} from '@common/guards/guest-guard.service';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: LandingComponent,
        canActivate: [GuestGuard],
    },
    {
        path: 'pricing',
        redirectTo: 'billing/pricing',
    },
    {path: 'link-groups/:slug', redirectTo: ':slug'},
    {
        path: 'dashboard',
        loadChildren: () =>
            import('src/app/dashboard/dashboard.module').then(
                m => m.DashboardModule
            ),
        canLoad: [AuthGuard],
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('src/app/admin/app-admin.module').then(
                m => m.AppAdminModule
            ),
        canLoad: [AuthGuard],
    },
    {
        path: 'billing',
        loadChildren: () =>
            import('@common/billing/billing.module').then(m => m.BillingModule),
    },

    {
        path: 'api-docs',
        loadChildren: () =>
            import('@common/api-docs/api-docs.module').then(
                m => m.ApiDocsModule
            ),
    },

    {path: 'contact', component: ContactComponent},
    {
        path: '**',
        pathMatch: 'full',
        component: LinkPreviewHostComponent,
        data: {willSetSeo: true},
    },
    ...NOT_FOUND_ROUTES,
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
