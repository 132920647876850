import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import {Bootstrapper} from '@common/core/bootstrapper.service';
import {Link} from '../../link/link';
import {BehaviorSubject} from 'rxjs';
import {MetaTag, MetaTagsService} from '@common/core/meta/meta-tags.service';
import {LinkGroup} from '../../link/link-group';
import {Biolink} from '../../../dashboard/biolinks/biolink';

interface LinkeableResponse {
    linkeable?: Link | LinkGroup | Biolink;
    seo: MetaTag[];
}

@Component({
    selector: 'link-preview-host',
    templateUrl: './link-preview-host.component.html',
    styleUrls: ['./link-preview-host.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkPreviewHostComponent implements OnInit {
    public linkeable: LinkeableResponse['linkeable'];
    public passwordConfirmed$ = new BehaviorSubject<boolean>(false);

    constructor(
        private bootstrapper: Bootstrapper,
        private metaTags: MetaTagsService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.bootstrapper.data.linkeableResponse?.linkeable) {
            const {linkeable, seo} = this.bootstrapper.data
                .linkeableResponse as LinkeableResponse;
            this.linkeable = linkeable;
            this.metaTags.latestMetaTags$.next(seo);
        }
        this.cd.markForCheck();
    }

    hidePasswordPanel() {
        if (
            this.linkeable.model_type === 'link' &&
            (this.linkeable as Link).type === 'direct'
        ) {
            window.location.replace((this.linkeable as Link).long_url);
        } else {
            this.passwordConfirmed$.next(true);
        }
    }

    getTableForLinkeable(linkeable: LinkeableResponse['linkeable']): string {
        if (linkeable.model_type === 'link') {
            return 'links';
        } else {
            return 'link_groups';
        }
    }
}
