import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ActiveAppSectionService {
    dashboard: boolean;
    admin: boolean;

    constructor(private router: Router) {
        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => {
                this.dashboard = e.urlAfterRedirects.startsWith('/dashboard');
                this.admin = e.urlAfterRedirects.startsWith('/admin');
            });
    }
}
