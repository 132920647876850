import { Injectable, Injector } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {WorkspacesService} from '@common/workspaces/workspaces.service';
import { ActiveAppSectionService } from './active-app-section.service';
import { AppHttpClient } from '@common/core/http/app-http-client.service';

@Injectable()
export class ActiveWorkspaceInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private injector: Injector,
        private activeSection: ActiveAppSectionService,
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (
            request.url.startsWith(AppHttpClient.prefix) &&
            this.activeSection.dashboard
        ) {
            // need to delay injection until current user service is initialized
            const workspaces = this.injector.get(WorkspacesService);
            if (workspaces.activeId$.value) {
                const newRequest = request.clone({
                    headers: request.headers.set(
                        'Be-Workspace-Id',
                        '' + workspaces.activeId$.value
                    ),
                });
                return next.handle(newRequest);
            }
        }
        return next.handle(request);
    }
}
