<div class="hero header-overlay" [style.background]="overlayBackground">
  <div
    class="background-image"
    [style.background-image]="'url(' + content.headerImage + ')'"
    [style.opacity]="content.headerImageOpacity"
  ></div>
  <svg viewBox="0 0 100 15" style="position: absolute; bottom: 0; left: 0; z-index: 2">
    <path fill="#fff" opacity="0.1" d="M0 30 V15 Q30 3 60 15 V30z"></path>
    <path class="wave-fill" d="M0 30 V12 Q30 17 55 12 T100 11 V30z"></path>
  </svg>
  <div class="hero-content">
    <material-navbar
      [transparent]="true"
      [container]="true"
      [menuPosition]="settings.get('vebto.navbar.defaultPosition', 'homepage-navbar')"
      class="login-button-accent"
    ></material-navbar>
    <div class="be-container">
      <h1 class="header-title" trans>{{ content.headerTitle }}</h1>
      <p class="header-description" trans>{{ content.headerSubtitle }}</p>
      <new-link-form
        *ngIf="currentUser.hasPermission('links.create') && settings.get('links.homepage_creation')"
        [content]="content"
      ></new-link-form>
      <div class="action-buttons">
        <a
          class="get-started-button"
          mat-raised-button
          color="accent"
          (click)="handleCtaClick($event, content.actions.cta1, router)"
          [href]="content.actions.cta1.action"
          *ngIf="content?.actions?.cta2?.label"
          trans
          >{{ content.actions.cta1.label }}</a
        >
        <button
          class="learn-more-button"
          mat-button
          (click)="handleCtaClick($event, content.actions.cta2, router)"
          *ngIf="content?.actions?.cta2?.label"
          trans
        >
          {{ content.actions.cta2.label }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <ad-host slot="ads.landing.top"></ad-host>
  <ul class="inline-features unstyled-list">
    <li class="inline-feature" *ngFor="let primaryFeature of content.primaryFeatures">
      <image-or-icon [src]="primaryFeature.image" class="inherit-size"></image-or-icon>
      <h3 trans>{{ primaryFeature.title }}</h3>
      <p trans>{{ primaryFeature.subtitle }}</p>
    </li>
  </ul>
</div>

<div class="spacer"></div>

<ul class="big-features unstyled-list">
  <ng-container
    *ngFor="
      let secondaryFeature of content.secondaryFeatures;
      first as first;
      last as last;
      even as even
    "
  >
    <li
      class="big-feature container"
      [class.first-secondary-feature]="first"
      [class.reverse]="even"
    >
      <image-or-icon [src]="secondaryFeature.image"></image-or-icon>
      <section class="info">
        <small trans>{{ secondaryFeature.subtitle }}</small>
        <h2 trans>{{ secondaryFeature.title }}</h2>
        <div class="feature-border"></div>
        <p trans>{{ secondaryFeature.description }}</p>
      </section>
    </li>

    <li class="spacer" *ngIf="!last"></li>
  </ng-container>
</ul>

<div class="spacer"></div>

<ng-container *ngIf="settings.get('links.homepage_stats')">
  <div class="stats container" *ngIf="stats$ | async as stats">
    <div class="stat">
      <mat-icon svgIcon="link"></mat-icon>
      <div class="info">
        <div class="text" trans>Total Links Created</div>
        <div class="number">{{ stats.links }}</div>
      </div>
    </div>
    <div class="stat">
      <mat-icon svgIcon="mouse"></mat-icon>
      <div class="info">
        <div class="text" trans>Total Link Clicks</div>
        <div class="number">{{ stats.clicks }}</div>
      </div>
    </div>
    <div class="stat">
      <mat-icon svgIcon="person"></mat-icon>
      <div class="info">
        <div class="text" trans>Users Signed Up</div>
        <div class="number">{{ stats.users }}</div>
      </div>
    </div>
  </div>
</ng-container>

<div class="bottom-cta" [style.background-image]="'url(' + content.footerImage + ')'">
  <div class="container">
    <h2 class="footer-title" trans>{{ content.footerTitle }}</h2>
    <p class="footer-description" trans>{{ content.footerSubtitle }}</p>
    <a
      mat-raised-button
      (click)="handleCtaClick($event, content.actions.cta1, router)"
      [href]="content.actions.cta1.action"
      *ngIf="content?.actions?.cta2?.label"
      trans
    >{{ content.actions.cta1.label }}</a
    >
  </div>
</div>

<app-footer class="container"></app-footer>
