import {Injectable} from '@angular/core';
import {BackendResponse} from '@common/core/types/backend-response';
import {LinkGroup} from './link-group';
import {LinkStats} from './link.service';
import {Link} from './link';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';
import {PaginationParams} from '@common/core/types/pagination/pagination-params';

@Injectable({
    providedIn: 'root',
})
export class LinkGroupService {
    static BASE_URI = 'link-group';
    constructor(private http: AppHttpClient) {}

    index(params: PaginationParams = {}): PaginatedBackendResponse<LinkGroup> {
        return this.http.get(`${LinkGroupService.BASE_URI}`, params);
    }

    create(
        params: Partial<LinkGroup>
    ): BackendResponse<{linkGroup: LinkGroup}> {
        return this.http.post(LinkGroupService.BASE_URI, params);
    }

    update(
        id,
        params: Partial<LinkGroup>
    ): BackendResponse<{linkGroup: LinkGroup}> {
        return this.http.put(`${LinkGroupService.BASE_URI}/${id}`, params);
    }

    delete(ids: number[]): BackendResponse<void> {
        return this.http.delete(`${LinkGroupService.BASE_URI}/${ids}`);
    }

    detach(groupId: number, linkIds: number[]) {
        return this.http.post(
            `${LinkGroupService.BASE_URI}/${groupId}/detach`,
            {linkIds}
        );
    }

    attach(groupId: number, linkIds: number[]) {
        return this.http.post(
            `${LinkGroupService.BASE_URI}/${groupId}/attach`,
            {linkIds}
        );
    }

    links(
        groupId: number,
        params: PaginationParams
    ): PaginatedBackendResponse<Link> {
        return this.http.get(
            `${LinkGroupService.BASE_URI}/${groupId}/links`,
            params
        );
    }

    analytics(
        groupId: number
    ): BackendResponse<{analytics: LinkStats; linkGroup: LinkGroup}> {
        return this.http.get(this.analyticsEndpoint(groupId));
    }

    analyticsEndpoint(groupId: number): string {
        return `${LinkGroupService.BASE_URI}/${groupId}/analytics`;
    }
}
