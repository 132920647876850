import {Injectable} from '@angular/core';
import {BackendResponse} from '@common/core/types/backend-response';
import {Link} from './link';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import { CrupdateLinkModalData } from '../../dashboard/link/crupdate-link-modal/crupdate-link-modal.component';

export interface LinkStats {
    totalClicks: number;
    clicks: {label: string; count: number}[];
    browsers: {label: string; count: number}[];
    locations: {
        label: string;
        code: string;
        percentage: number;
        count: number;
    }[];
    devices: {label: string; count: number}[];
    platforms: {label: string; count: number}[];
    referrers: {label: string; count: number}[];
    startDate: string;
    endDate: string;
}

export interface GetLinkResponse {
    link: Link;
    analytics: LinkStats;
}

export interface CrupdateLinkPayload extends Partial<Link> {
    groups?: {[key: number]: object};
    pivotData?: object;
    long_urls?: string[];
}

export type LinkReportRange = 'weekly' | 'monthly' | 'yearly';

@Injectable({
    providedIn: 'root',
})
export class LinkService {
    static BASE_URI = 'link';
    static EXPORT_CSV_URI = `${LinkService.BASE_URI}/csv/export`;
    constructor(private http: AppHttpClient) {}

    index(
        params: {
            userId?: number;
            query?: string;
            groupId?: number | string;
            per_page?: number;
        } = {}
    ): PaginatedBackendResponse<Link> {
        return this.http.get(`${LinkService.BASE_URI}`, params);
    }

    get(
        id: number,
        params: {range?: LinkReportRange; customRange?: string} = {}
    ): BackendResponse<GetLinkResponse> {
        return this.http.get(`${LinkService.BASE_URI}/${id}`, params);
    }

    create(params: CrupdateLinkPayload): BackendResponse<{link: Link}> {
        return this.http.post(LinkService.BASE_URI, params);
    }

    createBatch(data: CrupdateLinkPayload): BackendResponse<{links: Link[]}> {
        return this.http.post(`${LinkService.BASE_URI}/batch/shorten`, data);
    }

    update(id, params: CrupdateLinkPayload): BackendResponse<{link: Link}> {
        return this.http.put(`${LinkService.BASE_URI}/${id}`, params);
    }

    delete(ids: number[]): BackendResponse<void> {
        return this.http.delete(`${LinkService.BASE_URI}/${ids}`);
    }

    getCurrentUserReports(
        params: {range?: LinkReportRange; customRange?: string} = {}
    ): BackendResponse<{analytics: LinkStats}> {
        return this.http.get(`${LinkService.BASE_URI}/analytics`, params);
    }
}
