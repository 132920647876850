<div class="wrapper">
    <div class="panel">
        <mat-icon svgIcon="password"></mat-icon>
        <form class="content" (ngSubmit)="submitPassword()" ngNativeValidate>
            <div class="message" trans>The link you are trying to access is password protected.</div>
            <div class="input-container">
                <label for="password" class="hidden" trans>Password</label>
                <input type="password" id="password" placeholder="Password" [formControl]="passwordControl" trans-placeholder required>
            </div>
            <div class="button-container">
                <div class="error" *ngIf="passwordError$ | async" trans>Password does not match.</div>
                <button type="submit" mat-raised-button color="accent" trans>Enter</button>
            </div>
        </form>
    </div>
</div>