<ng-container *ngIf="linkeable">
  <request-password-panel
    *ngIf="linkeable?.has_password && !(this.passwordConfirmed$ | async); else linkeablePreview"
    [config]="{table: getTableForLinkeable(linkeable), id: linkeable.id}"
    (passwordValid)="hidePasswordPanel()"
  ></request-password-panel>

  <ng-template #linkeablePreview>
    <ng-container [ngSwitch]="linkeable.model_type">
      <link-group-page [group]="linkeable" *ngSwitchCase="'linkGroup'"></link-group-page>
      <biolink-page [biolink]="linkeable" *ngSwitchCase="'biolink'"></biolink-page>

      <ng-container *ngSwitchCase="'link'">
        <ng-container [ngSwitch]="linkeable['type']">
          <link-frame [link]="linkeable" *ngSwitchCase="'frame'"></link-frame>
          <link-frame [link]="linkeable" *ngSwitchCase="'overlay'"></link-frame>
          <link-splash [link]="linkeable" *ngSwitchCase="'splash'"></link-splash>
          <link-custom-page [link]="linkeable" *ngSwitchCase="'page'"></link-custom-page>
          <not-found-page *ngSwitchDefault></not-found-page>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
<not-found-page *ngIf="!linkeable"></not-found-page>
